import React from 'react';

export const SunsetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20">
    <g>
      <path d="M15.5 12H10a.5.5 0 00-.35.15L8 13.79l-1.65-1.64A.5.5 0 006 12H.5a.5.5 0 000 1h5.3l1.85 1.85a.5.5 0 00.7 0L10.21 13h5.29a.5.5 0 000-1z" />
      <path d="M3.42 11H6c.4 0 .78.16 1.06.44l.94.94.94-.94A1.5 1.5 0 0110 11h2.58a5 5 0 10-9.16 0zM8 3a.5.5 0 00.5-.5v-1a.5.5 0 00-1 0v1c0 .28.22.5.5.5zM.5 9.5h1a.5.5 0 000-1h-1a.5.5 0 000 1zM14.5 9.5h1a.5.5 0 000-1h-1a.5.5 0 000 1zM3.05 4.76a.5.5 0 00.7 0 .5.5 0 000-.71l-.7-.7a.5.5 0 00-.7.7l.7.7zM12.6 4.9a.5.5 0 00.35-.14l.7-.7a.5.5 0 00-.7-.72l-.7.71a.5.5 0 00.35.85z" />
    </g>
  </svg>
);
