import React from 'react';

export const SunriseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20">
    <g>
      <path d="M15.5 12h-5.3l-1.85-1.85a.5.5 0 00-.7 0L5.79 12H.5a.5.5 0 000 1H6a.5.5 0 00.35-.15L8 11.21l1.65 1.64c.09.1.22.15.35.15h5.5a.5.5 0 000-1z" />
      <path d="M3.42 11h1.96l1.56-1.56a1.5 1.5 0 012.12 0L10.62 11h1.96a5 5 0 10-9.16 0zM8 3a.5.5 0 00.5-.5v-1a.5.5 0 00-1 0v1c0 .28.22.5.5.5zM.5 9.5h1a.5.5 0 000-1h-1a.5.5 0 000 1zM14.5 9.5h1a.5.5 0 000-1h-1a.5.5 0 000 1zM3.05 4.76a.5.5 0 00.7 0 .5.5 0 000-.71l-.7-.7a.5.5 0 00-.7.7l.7.7zM12.6 4.9a.5.5 0 00.35-.14l.7-.7a.5.5 0 00-.7-.72l-.7.71a.5.5 0 00.35.85z" />
    </g>
  </svg>
);
